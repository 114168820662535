body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body .renderHtml * {
  font-family: "Poppins-Regular", sans-serif !important;
}

body .renderHtml div[data-testid="p"] *,
body .renderHtml div[data-testid="li"] * {
  font-size: 12px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

ul {
  list-style: none;
}

ul.react-paginate {
  display: flex;
  align-items: center;
  gap: 8px;
}

ul.react-paginate li.active a div div {
  color: "#D3B100" !important;
}

.nsm7Bb-HzV7m-LgbsSe {
  height: 45px !important;
  width: 60% !important;
  margin: 0 auto !important;
  background-color: black !important;
  color: white !important;
  border-color: rgb(52, 57, 61) !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-BPrWId {
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif !important;
  font-weight: 400 !important;
  -webkit-flex-grow: unset !important;
  flex-grow: unset !important;
}

.nsm7Bb-HzV7m-LgbsSe .nsm7Bb-HzV7m-LgbsSe-bN97Pc-sM5MNb {
  justify-content: center !important;
}
